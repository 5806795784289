import {translations} from "../classes/translations";
import {PatientItem} from "../classes/Patient/PatientItem";
import {KeyValuePair} from "../classes/Delphi";
import * as Fhir from "../classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse} from "../classes/FhirModules/Fhir";
import {NitTools} from "../classes/NursitTools";
import {BasicForm} from "./BasicForm";
import {saveButton} from "./save-button";
import {UserService} from "../services/UserService";
import {QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {ReportService} from "resources/services/ReportService";
import {fhirEnums} from "../classes/fhir-enums";
import {DateTimeValueConverter} from "../value-converters/dateFormatValueConverter";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {IQuestionnaireListItem} from "../classes/IQuestionnaireListItem";
import {I18N} from "aurelia-i18n";
import {Router} from "aurelia-router";
import {autoinject, computedFrom, TaskQueue} from "aurelia-framework";
import {PatientChangeNotifier} from "../services/PatientChangeNotifier";
import {DialogService} from "aurelia-dialog";
import {AnalyzeService} from "../services/analyzeService";
import {DialogMessages} from "../services/DialogMessages";
import {ConfigService} from "../services/ConfigService";
import {formSelectResponseDialog} from "./form-select-response-dialog";
import {HttpClient} from "aurelia-http-client";
import {IdLogikService} from "../services/IdLogikService";
import {qChangeNotifier} from "./questionnaire/q-changeNotifier";
import SystemHeaders from "../classes/SystemHeaders";
import {FhirService} from "../services/FhirService";
import {IFormSetting, IMailSettings} from "../classes/IFormSettings";
import {PermissionService} from "resources/services/PermissionService";
import {LogoutService} from "../services/LogoutService";
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;

const moment = require("moment");

@autoinject
export class FormBaseClass extends BasicForm {
    public static UseQuestionnaireStatusForSave: boolean = true;

    private _dateChangeable = undefined;
    public get dateChangeable() : boolean {
        if (typeof this._dateChangeable === "undefined")
            return !this.readonly;

        return this._dateChangeable;
    }

    public set dateChangeable(value : boolean) {
        this._dateChangeable = value;
    }

    _translateButton: boolean = true;
    __noResponsesText: string = undefined;
    _showPkms: boolean = false;
    _buttonText: string;
    questionnaireClass;
    allGroupsHidden: boolean = false;
    documentLoaded: boolean = false;
    
    subscriberId: string = undefined;
    pkmsSum: number = 0;
    showTrashcan: boolean = true;
    dateFormat = "date_time_format_short";
    useQuestionnaireStatusForSave: boolean = true;
    removeNoToolbarWindow: boolean = true;
    
    responseNotifierSubScriberId: string;
    noPatientProblemsText: string = translations.translate("no_patient_problems_default");
    autoLoadId: string = undefined;
    bundleItems: any[] = [];
    bundleEntries: any[] = [];
    dateTimeConverter: DateTimeValueConverter = new DateTimeValueConverter();
    showResponseSelect: boolean = true;
    showSettings: boolean = true;
    showActivateButton: boolean = true;
    showButtonComplete: boolean = true;
    reAssignResponses: boolean = true;

    get showPkms(): boolean {
        return this._showPkms && this.patient.isEncounterDuringPkmsPeriod;
    }

    set showPkms(value: boolean) {
        this._showPkms = value;
    }

    get isMobile(): boolean {
        return RuntimeInfo.IsMobile;
    }

    get hasTableReport(): boolean {
        if (!this.setting || !this.setting.report) return false;
        return (
            typeof this.setting.report.progressSuffix === "string" &&
            this.setting.report.progressSuffix !== ""
        );
    }

    get buttonText(): string {
        if (this._translateButton) {
            let text = this.i18n.tr(this._buttonText);
            if (text !== this._buttonText) {
                this._buttonText = text;
                this._translateButton = false;
            }
        }

        return this._buttonText;
    }

    set buttonText(value: string) {
        this._translateButton = true;
        this._buttonText = value;
    }

    get showRenameButton() {
        return ConfigService.Debug;
    }

    get noResponsesText(): string {
        if (!this.__noResponsesText && this.questionnaire) {
            if (
                (!this.pageTitle && this.questionnaire.title) ||
                this.questionnaire.name
            ) {
                let title = this.questionnaire.title || this.questionnaire.name;
                return this.i18n
                    .tr("no_current_docu")
                    .replace(/%NAME%/g, title);
            } else {
                this.__noResponsesText = this.i18n
                    .tr("no_current_docu")
                    .replace(/%NAME%/g, this.pageTitle);
            }
        }

        return this.__noResponsesText;
    }

    get hasResponses() {
        if (!this.responses && this.response)
            this.responses = [this.response];

        return this.responses && this.responses.length > 0;
    }

    get tooOldText() {
        let s: string = translations.translate("no_edit_after_24h_info");
        s = s.replace(/%HOURS%/g, this.maxAge.toString());

        return s;
    }

    get responseStatus(): string {
        return this.response
            ? translations.translate(this.response.status.toString())
            : "-";
    }

    get selectedIndex() {
        return this.hasResponses && this.response
            ? this.responses.findIndex((o) => o.id === this.response.id)
            : -1;
    }

    get saveButtonIconClass(): string {
        let result = "";

        if (this.response) {
            if (this.tooOld) result = "mdi-alarm";
            else {
                if (this.readonly) {
                    result = "mdi-edit";
                } else {
                    if (this.response.status === "in-progress") {
                        result = "mdi-done-all";
                    } else {
                        result = "mdi-save";
                    }
                }
            }
        }

        return result;
    }

    get saveButtonText(): string {
        if (!this.tooOld && this.readonly) {
            return this.i18n.tr("edit");
        } else {
            return this.i18n.tr(this.tooOld ? "edit" : "save");
        }
    }

    constructor(protected i18n: I18N,
        router: Router, queue: TaskQueue, notifier: PatientChangeNotifier, dialogService: DialogService,
        analyzeService: AnalyzeService,
        protected patientService: PatientService,
        protected dialogMessages: DialogMessages,
        protected idLogikService: IdLogikService,
        protected responseNotifier: qChangeNotifier,
        protected permissionService: PermissionService,
        protected logoutService : LogoutService) {
        super(i18n, router, queue, notifier, dialogService, analyzeService, patientService, dialogMessages, idLogikService, permissionService, logoutService);
        this.responseNotifierSubScriberId = this.responseNotifier.subscribe((item) => this.checkGroupVisibilites(item));
    }

    async createFromTemplateButtonClicked(item) {
        console.warn(item);
    }

    override async attached() {
        this.isLoading = true;
        await super.attached();

        await this.loadData();
        this.afterAttached();

        let setting = ConfigService.GetFormSettings(this.route || this.questionnaireName||this.questionnaire.name);
        if (!setting && this.questionnaire) {
            setting = ConfigService.GetFormSettings(this.questionnaire.name);
        }

        this.report = setting?.report?.name || this.questionnaire?.name;

        this.showPrintButton = (!!ReportService.ReportServer) && !!this.report;
        if (!this.showPrintButton && ReportService.ReportServer && this.questionnaire) {
            const reports = await ReportService.Fetch();
            const fallBack = `${(this.questionnaire.name || this.questionnaire.title).toUpperCase()}.FRX`;
            this.showPrintButton = reports.items.indexOf(fallBack) > -1;
            this.report = fallBack;
        }

        this.showPdfButton =
            !RuntimeInfo.IsMobile &&
            this.showPrintButton &&
            RuntimeInfo.Features.showFastPdfPrintButton === true; // use type inspection here, don't show on mobile devices
        this.useQuestionnaireStatusForSave = true;

        this.isLoading = false;

        /*if (ConfigService.Debug)
            console.debug(`Changed Questionnaire to: "${this.questionnaireName}"`);*/
    }

    async activate(params) {

        this.isLoading = true;
        try {
            this.init();

            if (params.id) {
                this.encounterId = params.id;
                await ConfigService.LoadConfigOverride(this.patientService?.latestPatient?.ward, this.patientService.latestPatient);
            }

            if (params.responseId) {
                this.autoLoadId = params.responseId;
            }
        }
        finally {
            this.isLoading = false;
        }
    }

    async detached() {
        this.beforeDetached();
        NitTools.releaseScrollLock("form.ni-form");

        // document.body.classList.remove("no-toolbar-window");
        if (this.response && this.response.id && !this.readonly && !this.tooOld) {
            try {
                // await this.headerSaveButtonClicked();
            } catch (e) {
                console.warn("When autosaving Response:" + e.message);
            }
        }

        if (this.removeNoToolbarWindow) {
            document.body.classList.remove("no-toolbar-window");
        }

        return Promise.resolve();
    }

    beforeDetached() {
        this.notifier.unsubscribe(this.subscriberId);
        this.responseNotifier.unsubscribe(this.responseNotifierSubScriberId);
    }

    afterAttached() {
        if (ConfigService.Debug) window["formBase"] = this;
        NitTools.fixScrollLock("form.ni-form");

        this.subscriberId = this.notifier.subscribe(
            (patient: PatientItem) => {
                if (
                    this.patient &&
                    this.patient.id === patient.id &&
                    this.patient.encounterId === patient.encounterId
                ) {
                    this.patientUpdated().catch((error) => {
                        console.warn(error);
                    });
                }
            }
        );
    }

    init() {
        if (this.i18n) {
            this.dateFormat = this.i18n.tr("date_time_format_short");
            this.noPatientProblemsText = this.i18n.tr(
                "no_patient_problems_default"
            );
        }
    }

    /**
     * This is the event handler when the user checked/unchecked a group in the group list
     * @param $event the QuestionnaireItem (here:group) that has been changed
     */
    protected onVisibleGroupsChanged($event) {
        this.checkGroupVisibilites($event);
    }

    checkGroupVisibilites(item) {
        if (!item || item.type !== "group" || !this.response) return;

        let _allHidden: boolean = false;
        if (this.response.extension) {
            const groupExtensions = this.response.extension.filter(o => o.url && o.url.indexOf('/questionnaire-group-visible') > -1 && typeof o.valueBoolean !== "undefined");

            if (groupExtensions.length > 0) {
                let hasVisibleGroups = false;

                for (const extension of groupExtensions) {
                    if (extension.valueBoolean === true) {
                        hasVisibleGroups = true;
                        break;
                    }
                }

                _allHidden = !hasVisibleGroups;
            }
        }

        if (_allHidden)
            window.setTimeout(() => this.allGroupsHidden = _allHidden, 350);
        else
            this.allGroupsHidden = _allHidden;
    }

    formId : string = NitTools.UidName();
    override async afterResponseChanged(response: any): Promise<void> {
        await super.afterResponseChanged(response);
        this.checkGroupVisibilites({ type: "group" });
    }

    // noinspection JSUnusedLocalSymbols
    responseAssigned(assignedResponse: any) {
        this.responseBackup = NitTools.Clone(
            this.response ? this.response.item : undefined
        );
    }

    async recalcRisks(
        assessment: any,
        saveToServer: boolean
    ) {
        if (!assessment || this.readonly) return;
        let oldAi = NitTools.Clone(this.patient.selectedAdditionalInfo);

        let result = await this.analyzeService.analyse(
            this.patient,
            this.patient.latestAssessment,
            true,
            false// saveToServer
        );
        this.showPkms = Fhir.Tools.StripId(this.response.questionnaire) === this.qList.QAssessmentId
            && result
            && result.pkmsIsRisk;

        if (this.patient.selectedAdditionalInfo) {
            if (
                Fhir.Tools.ResponseValuesDiffer(
                    oldAi,
                    this.patient.selectedAdditionalInfo
                )
            ) {
                let newAI = <any>(
                    await this.fhirService.update(
                        this.patient.selectedAdditionalInfo
                    )
                );
                PatientService.AddQuestionnaireResponse(
                    this.patient,
                    newAI,
                    true
                );
                this.patient.selectedAdditionalInfo = newAI;
            }
        } /* else {
            console.warn(
                "Patient has no selected additional info present to store risks"
            );
        } */

        this.notifier.notify(this.patient);
    }

    async startEditForm() {
        if (this.tooOld) {
            this.showEditButtons = false;
            this.mayBeEdited = false;
            this.readonly = true;
            return;
        }

        this.showEditButtons = true;
        this.mayBeEdited = true;
        this.readonly = false;
    }

    async patientUpdated() {
    }

    referenceClicked(url) {
        window.location.hash = url;
    }

    showSaveFirstDialog() {
        this.dialogMessages.prompt(
            this.i18n.tr("print_unsaved_changes"),
            this.i18n.tr("information"),
            false
        );
    }

    async pdfButtonClicked() {
        await this.printButtonClicked(false, true);
    }

    handleReportError(e) {
        alert(e.error || e.message || JSON.stringify(e));
        // ReportService.ShowReportError(this.dialogService, e, this.i18n);
    }

    async printButtonClicked(isMultiPrint?: boolean, openPfd?: boolean) {
        await ReportService.Fetch();
        await this.ensureSettings();

        if (!this.readonly && this.hasChanges) {
            this.showSaveFirstDialog();
            return;
        }

        // check if the requirements are met, iE Assessment + Anamnesis for "Stammblatt"
        let check = await ReportService.RequirementsFulfilled(
            this.i18n,
            this.setting,
            this.patient
        );

        if (!check.fulfilled) {
            let html = "";
            for (const m of check.message) {
                html += `<div class="report-fulfillment-warning">${m.text}</div>`;
            }

            this.dialogMessages.prompt(
                html,
                this.i18n.tr("print_requirements_not_met"),
                true
            );
            return;
        }

        let localReportName = this.questionnaireName.toUpperCase() + '.FRX';
        let reports = ReportService.Reports.items.map(o=>o.toUpperCase());
        for (let i = 0; i < reports.length; i++) {
            if (!reports[i].endsWith('.FRX'))
                reports[i] += '.FRX';
        }

        let report = reports.indexOf(localReportName) > -1 ? localReportName : this.report;
        if (/\.frx/i.test(report)) {
            report = report.replace(/\.frx/i, "");
        }

        if (isMultiPrint) {
            report = report + this.setting.report.progressSuffix;
        }

        ReportService.Preview(this.response.id, report, undefined, !!openPfd);
    }

    // noinspection JSUnusedLocalSymbols
    async beforeSaveNewItem(newResponse: any) {        
        if (this.response && newResponse && this.response.extension && this.response.extension.length > 0) {
            newResponse.extension = [];
            for (const ext of this.response.extension
                .filter((o) => o.url.indexOf("/questionnaire-group-visible") > -1)) {
                    newResponse.extension.push(NitTools.Clone(ext));
                }
        }
    }

    setPreviousValues(newResponse: any) {
        if (!this.questionnaire || !newResponse) {
            return;
        }

        try {
            // cleanup!
            Fhir.Questionnaire.EnsureStructuredResponse(
                this.questionnaire,
                newResponse
            );

            // consider if previous values should be take from latest finalized response.
            // taken from Setting <form>.takeOverPreviousValues? true/false
            const takeOverValues = (this.setting && typeof this.setting.takeOverPreviousValues === "boolean") ? this.setting.takeOverPreviousValues : true;
            let previousResponse = takeOverValues ?
                    QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaire.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended])
                    : undefined;

            if (previousResponse === undefined) {
                // no previous response existent, so insert the default values
                let defaultValues: KeyValuePair[] = Fhir.Questionnaire.GetDefaultQuestionnaireValues(
                    this.questionnaire
                );

                for (const item of defaultValues) {
                    Fhir.QuestionnaireResponse.SetResponseItemCoding(
                            Fhir.QuestionnaireResponse.GetResponseItemByLinkId(newResponse, item.key,true),
                            item.value
                    );
                }

                // move from coding to the correct values
                Fhir.Questionnaire.FixResponseAnswers(
                    this.questionnaire,
                    newResponse
                );
            } else {
                // we got a previous response, so just clone the items. Default values should have been set to the prev. item when generated, so don't mind
                newResponse.item = NitTools.Clone(previousResponse.item);
            }
        } catch (e) {
            console.warn(e);
        }
    }

    afterCreate(result: any) {
        this.closeSaveButton();
        this.isLoading = false;
        this.selectedid = result.id;
        this.showEditButtons = true;
        this.readonly = false;
        this.tooOld = false;
    }

    protected async createDocumentOnFhir(newResponse: any): Promise<any> {
        const result = await FormBaseClass.CreateDocumentOnFhir(newResponse, this.patient);

        let tmp = QuestionnaireService.GetResponsesOfType(
            this.patient,
            this.questionnaire.id
        ); //  Fhir.Tools.GetQuestionnaireListForCombo(this.questionnaire.id, this.patient, true);

        tmp.forEach(qr => qr.text = {
            status: "generated",
            //                 div: `<div xmlns="http://www.w3.org/1999/xhtml">${moment(qr.authored).format(RuntimeInfo.DateTimeFormat)}</div>`,
            div: `<div xmlns="http://www.w3.org/1999/xhtml">${this.questionnaire.name}, ${moment(qr.authored).format(RuntimeInfo.DateTimeFormat)}</div>`,
        }
        );

        this.responses = tmp;
        this.selectedid = result.id;
        // this.response = result.resultObject;
        this.checkForPrintButton();

        this.afterCreate(result);
        this.isLoading = false;

        const settingAssessment = ConfigService.GetFormSettings('assessment');
        const settingAnamnesis = ConfigService.GetFormSettings('anamnesis');

        if (this.response
            && settingAssessment && settingAnamnesis
            && (this.questionnaire.name.toUpperCase() == settingAnamnesis.questionnaireName.toUpperCase()
                || this.questionnaire.name.toUpperCase() == settingAssessment.questionnaireName.toUpperCase()
            )
        ) {
            await this.analyzeService.analyse(this.patient, this.patient.latestAssessment, true, true);
        }

        return result;
    }

    protected static async CreateDocumentOnFhir(newResponse: any, patient: PatientItem): Promise<any> {
        if ((!newResponse)) {
            console.warn("No response created!");
            return;
        }

        const cfg = await ConfigService.LoadConfigOverride(patient?.ward, patient);
        await QuestionnaireService.Fetch(true);
        const questionnaire = QuestionnaireService.GetQuestionnaireDirect(newResponse?.questionnaire);

        if (!newResponse.extension) newResponse.extension = [];
        if (!newResponse.id) newResponse.id = NitTools.Uid();

        // remember the selected preset
        // const presetExtension = Fhir.Tools.GetOrCreateExtension(newResponse, 'response-preset', false);

        // remove all extension except the group-visibility and/or analyzer Version even as the selected response
        newResponse.extension = newResponse.extension.filter(o =>
            o.url.indexOf('questionnaire-group-visible') > -1
            || o.url.indexOf('analyzing/AnalyzerVersion') > -1
            || o.url.indexOf('response-preset') > -1
        );

        if (questionnaire?.version) {
        const versionExtension = Fhir.Tools.GetOrCreateExtension(newResponse, 'questionnaire-version', true);
        if (versionExtension)
            versionExtension.valueString = questionnaire.version;
        }

        const toCreate = [newResponse];
        const setting = cfg.forms.find(o => o.questionnaireName && o.questionnaireName.toUpperCase() === (questionnaire.name || questionnaire.title).toUpperCase());

        // remove the old links
        if (NitTools.IsArray(setting?.autoGenerateNewResponses)) {
            if (ConfigService.Debug)
                console.debug("Automatically creating attached documents from config \"" + setting.route + "\": ", setting.autoGenerateNewResponses);

            for (const autoSetting of setting.autoGenerateNewResponses) {
                if (autoSetting.questionnaire) {
                    const autoQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(autoSetting.questionnaire);
                    if (!autoQuestionnaire) {
                        console.warn(`Questionnaire with name "${autoSetting.questionnaire}" not found for automatic creation. Check Config for Questionnaire "${questionnaire.name}"`);
                    } else {
                        if (typeof toCreate.find(o =>
                            (o.questionnaire && o.questionnaire.reference && o.questionnaire.reference.indexOf('Questionnaire/' + autoQuestionnaire.id) > -1)
                            || (typeof o.questionnaire === "string" && String(o.questionnaire).endsWith(`/${autoQuestionnaire.name}`))
                            ) === "undefined"
                           ) {   // only add to the array to create when no other Questionnaire with this Id is created
                            newResponse.extension = newResponse.extension.filter(o => o.url && o.url.indexOf('/' + autoQuestionnaire.name) === -1);
                            const autoResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, autoQuestionnaire.id, 'in-progress');
                            const previousResponse = QuestionnaireService.GetLatestResponseOfType(patient, autoQuestionnaire.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                            if (autoSetting.usePreviousValues === true)
                                QuestionnaireResponse.SetDefaultValues(autoResponse, previousResponse);

                            Fhir.Questionnaire.EnsureStructuredResponse(autoQuestionnaire, autoResponse);
                            QuestionnaireResponse.EnsureStructuralHullIntegrity(autoQuestionnaire, autoResponse);

                            if (autoResponse.extension) {
                                autoResponse.extension = autoResponse.extension.filter(o => o.url.indexOf(`StructureDefinition/${questionnaire.name}`) === -1);
                            }

                            Fhir.QuestionnaireResponse.SetAttachedResponse(patient, newResponse, autoResponse);
                            Fhir.QuestionnaireResponse.SetAttachedResponse(patient, autoResponse, newResponse);

                            toCreate.push(autoResponse);
                        }
                    }
                }
            }
        }

        await Fhir.Rest.Bundle(toCreate, HTTPVerb.put, BundleType.transaction);
        toCreate.forEach(resp => PatientService.AddQuestionnaireResponse(patient, resp));

        return newResponse;
    }

    removeTargetDates(items : any[]) {
        for (const item of items) {
            if (item.item) {
                this.removeTargetDates(item.item);
            }

            if (item?.extension) {
                item.extension = item.extension.filter(o=>!o.url.endsWith('/target-date')
                                && !o.url.endsWith('/target-value'));
                if (item.extension.length === 0)
                    delete item.extension;
            }
        }
    }

    async fillDefaultValuesFromPreset(skel: any, file : string) {
        return new Promise((resolve, reject) => {
            let url = `config/ward_configs/presets/${file}`;
            if (ConfigService.Debug)
                console.debug(`Loading Values from Preset:`, file);

            new HttpClient().get(url)
                .then((responseMessage) => {
                    if (responseMessage.response.trim().indexOf('[') === 0) {
                        try {
                            let js = JSON.parse(responseMessage.response);
                            skel.item = NitTools.Clone(js);
                            skel.extension.push({
                                url: `${SystemHeaders.vendorBase}fhir/StructureDefinition/response-preset`,
                                valueString: file
                            });

                            this.removeTargetDates(skel.item);

                        } catch (error) {
                            let s = `Error when getting default values from file ${file}.\n                                                                   
                                                                   Error was: "${error.message || JSON.stringify(error)}"\n
                                                                   Continuing with using form-defaults for default values.`;
                            console.warn(s);
                            this.dialogMessages.prompt(s.replace(/\n/gi, '<br />'), this.i18n.tr('warning'), true);
                            this.setPreviousValues(skel);
                        }

                        return resolve(skel);
                    } else {
                        let s = 'Preset file in wrong format. Should just be an array of fhir questionnaireResponseItem (and start with "[" )';
                        console.warn(s);
                        this.dialogMessages.prompt(s, this.i18n.tr('warning'), true);

                        return reject(s);
                    }
                })
                .catch(r => {
                    let s = `Getting "${url}" resultet in: ${r.statusCode} - ${r.statusText}`;
                    this.dialogMessages.prompt(s, this.i18n.tr('error'), true);

                    return reject(s);
                });
        });
    }

    async showPredefinedResponsesList(defaultResponseItem, skel): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.isLoading = false;
            this.dialogService.open({
                viewModel: formSelectResponseDialog,
                model: {
                    items: defaultResponseItem
                }
            }).whenClosed(async result => {
                if (result.wasCancelled) {
                    // throw 'Creation aborted by user';
                    return reject('Creation aborted by user');
                } else {
                    if (result.output) {
                        await this.fillDefaultValuesFromPreset(skel, result.output);
                        // let url = `config/ward_configs/presets/${result.output}`;
                        // new HttpClient().get(url)
                        //     .then((responseMessage) => {
                        //         if (responseMessage.response.trim().indexOf('[') === 0) {
                        //             try {
                        //                 let js = JSON.parse(responseMessage.response);
                        //                 skel.item = NitTools.Clone(js);
                        //                 skel.extension.push({
                        //                     url: `${SystemHeaders.vendorBase}fhir/StructureDefinition/response-preset`,
                        //                     valueString: result.output
                        //                 });
                        //             } catch (error) {
                        //                 let s = `Error when getting default values from file ${result.output}.\n
                        //                                            Error was: "${error.message || JSON.stringify(error)}"\n
                        //                                            Continuing with using form-defaults for default values.`;
                        //                 console.warn(s);
                        //                 this.dialogMessages.prompt(s.replace(/\n/gi, '<br />'), this.i18n.tr('warning'), true);
                        //                 this.setPreviousValues(skel);
                        //             }
                        //
                        //             return resolve(skel);
                        //         } else {
                        //             let s = 'Preset file in wrong format. Should just be an array of fhir questionnaireResponseItem (and start with "[" )';
                        //             console.warn(s);
                        //             this.dialogMessages.prompt(s, this.i18n.tr('warning'), true);
                        //
                        //             return reject(s);
                        //         }
                        //     })
                        //     .catch(r => {
                        //         let s = `Getting "${url}" resultet in: ${r.statusCode} - ${r.statusText}`;
                        //         this.dialogMessages.prompt(s, this.i18n.tr('error'), true);
                        //
                        //         return reject(s);
                        //     });
                    } else {
                        Questionnaire.SetDefaultValues(this.questionnaire, skel, this.previousResponse);
                    }

                    return resolve(skel);
                }
            });
        });
    }

    async confirmDiscardChanges(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                if (!this.isLoading && this.hasChanges) {
                    this.dialogMessages.dialog(this.i18n.tr("confirm_discard_unsaved_changes"),
                        this.i18n.tr("confirm"), this.i18n.tr("no"), this.i18n.tr("yes"), true)
                        .whenClosed(result => {
                            resolve(result.wasCancelled);
                        });
                } else {
                    resolve(true);
                }
            }
            catch (e) {
                console.warn(e);

                reject(e);
            }
        });
    }

    async createButtonClicked(preset) : Promise<any> {
        if (!this.patient || this.patient.isOffline) return;

        if (preset && !preset["file"])
            preset = undefined;

        // if changes are made in the current response, get the confirmation to rollback changes
        const discard = await this.confirmDiscardChanges();
        if (!discard) {
            // user does not want to discard changes, so do nothing
            return;
        } else {
            // user wants to undo changes
            if (this.hasChanges) {
                // undo the changes by getting back the responseBackup-Item(s)
                this.response.item = NitTools.Clone(this.responseBackup);
            }
        }

        this.isLoading = true;

        let qrSkeleton: any = Fhir.Tools.SubstituteDefaultQRSkeleton(
            this.patient,
            this.questionnaire.id,
            fhirEnums.QuestionnaireResponseStatus.inProgress
        );

        // just to be sure ..
        if (!qrSkeleton.context && this.patient && this.patient.encounterId) {
            qrSkeleton.context = {
                reference: `${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}`,
            };
        }

        if (!qrSkeleton.item) qrSkeleton.item = [];

        // skel = QuestionnaireResponse.SetDefaultValuesFix(this.questionnaire, skel);  // QuestionnaireResponse.SetDefaultValues(skel, this.previousResponse);

        // set the initial values
        let itemIds = Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire);
        itemIds.forEach(id => {
            let questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, id);
            if (questionnaireItem && questionnaireItem.initialCoding) {
                let rItem = QuestionnaireResponse.GetResponseItemByLinkId(qrSkeleton, id);
                if (questionnaireItem.initialCoding) {
                    if (rItem && (!rItem.answer || rItem.answer.length === 0)) {
                        let code = questionnaireItem.initialCoding.code;
                        if (code.indexOf('=') !== 0) {
                            if (questionnaireItem && !questionnaireItem.option && questionnaireItem["answerOption"]) // R4 fix
                                questionnaireItem.option = questionnaireItem["answerOption"];

                            let display = questionnaireItem.initialCoding.display;
                            if (questionnaireItem && !questionnaireItem.option && questionnaireItem["answerOption"]) // R4 fix
                                questionnaireItem.option = questionnaireItem["answerOption"];

                            if (questionnaireItem.option) {
                                let displayOption = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === code);
                                if (displayOption) {
                                    display = displayOption.valueCoding.display;
                                }
                            }

                            rItem.answer = [{
                                valueCoding: {
                                    code: code,
                                    display: display
                                }
                            }];
                        }
                    }
                } else if (questionnaireItem.initialInteger) {
                    rItem.answer = [{ valueInteger: questionnaireItem.initialInteger }];
                } else if (questionnaireItem.initialDecimal) {
                    rItem.answer = [{ valueDecimal: questionnaireItem.initialDecimal }];
                } else if (questionnaireItem.initialBoolean) {
                    rItem.answer = [{ valueBoolean: questionnaireItem.initialBoolean }];
                } else if (questionnaireItem.initialDate) {
                    rItem.answer = [{ valueDate: questionnaireItem.initialDate }];
                } else if (questionnaireItem.initialString) {
                    rItem.answer = [{ valueString: questionnaireItem.initialString }];
                }
            }
        });

        const customConfig = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        const formSetting = ConfigService.GetFormSettings(this.route);

        const previousResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, this.questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
        if (!preset && customConfig && formSetting && formSetting["defaultResponseItem"] && (formSetting["alwaysUsePresets"] === true || !previousResponse)) {
            await this.showPredefinedResponsesList(formSetting["defaultResponseItem"], qrSkeleton);
        } else {
            if (preset) {
                await this.fillDefaultValuesFromPreset(qrSkeleton, preset.file);
            } else {
                this.setPreviousValues(qrSkeleton);
            }
        }

        await this.beforeSaveNewItem(qrSkeleton);

        return await this.createDocumentOnFhir(qrSkeleton);
    }

    checkSave(newId: string) {
        return new Promise<any>((resolve, reject) => {
            if (
                this.response &&
                this.response.id !== newId &&
                !this.readonly &&
                !this.tooOld &&
                !this.isLoading /* && this.hasChanges */
            ) {
                this.isLoading = true;

                // workaround for planio issue #3157
                QuestionnaireResponse.EnsureValidValues(this.patient, this.response, false)
                .then(() => {
                    this.fhirService
                        .update(this.response)
                        .then(() => {
                            this.selectedid = newId;
                            this.isLoading = false;
                            resolve(true);
                        })
                        .catch((error) => {
                            console.warn(error);
                            reject("Error when updating current response");
                        });
                    }
                )
                .catch(err => console.warn(err));
            } else {
                resolve(true);
            }
        });
    }

    getQuestionnaireListItem(q: any) {
        return {
            id: q.id,
            text: moment(q.authored).format(this.dateFormat),
            date: moment(q.authored).toDate(),
        };

        // let tzoffset = (new Date()).getTimezoneOffset();
        //        r.text = moment(q.authored).subtract(tzoffset, "minutes").format(this.dateFormat);
    }

    getQuestionnaireListForCombo(
        questionnaireId?: string
    ): IQuestionnaireListItem[] {
        this.questionnaireClass.questionnaire;
        return this.patientService.getQuestionnaireListForCombo(
            questionnaireId,
            this.patient,
            true
        );
    }

    async ensureSettings() : Promise<IFormSetting> {
        if (!this.setting && (this.route || this.questionnaireName)) {
            await ConfigService.LoadConfigOverride(this.patient?.ward);
            this.setting = ConfigService.GetFormSettings(this.route || this.questionnaireName);
            if (!this.setting) {
                this.setting = ConfigService.cfg?.forms?.find(o => String(o.questionnaireName).toUpperCase() == this.questionnaireName.toUpperCase());
            }
        }

        this.report = this.setting?.report?.name;

        return this.setting;
    }

    async afterSave(response: any) {
        if (!this.patient) return;

        PatientService.AddQuestionnaireResponse(this.patient, response, true);
        await this.ensureSettings();

        // move this response, which is a backup into it's original position in the questionnaires
        let existing = this.patient.questionnaireResponses.find((o: any) => o.id === response.id);
        if (existing) {
            let idx = this.patient.questionnaireResponses.indexOf(existing);
            if (idx > -1) {
                this.patient.questionnaireResponses[idx] = response;
            }
        }

        const assessmentName = this.patient.getAssessmentName();
        const assessment = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentName);
        if (assessment.id === this.questionnaire.id) {
            this.patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(
                this.patient,
                assessment.id,
                [
                    fhirEnums.QuestionnaireResponseStatus.amended,
                    fhirEnums.QuestionnaireResponseStatus.completed,
                ]
            );
        }

        this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined);

        await this.analyzeService.validateMarks(this.patient);

        this.isLoading = false;

        RuntimeInfo.ShowInfo(this.i18n.tr("changes_saved_successfully"));

        if (ReportService.ReportServer && ['amended', 'completed'].indexOf(response.status) > -1) {
            let report = this.setting?.report?.autoSaveReportName || this.report;

            // determine whether the autoSaveReportName Property is a string or an array..
            if (this.setting?.report?.autoSaveReportName) {
                let autoSave = this.setting.report.autoSaveReportName;
                if (NitTools.IsArray(autoSave)) {   // iterate through all given reports and request to save them
                    for (const r of autoSave) {
                        try {
                            ReportService.SendAutoSave(response.id, r, this.patient)
                                .catch(error => console.warn(error));
                        }
                        catch (ex) {
                            console.warn(ex);
                        }
                    }
                } else {    // only a single string as reportname given, so send that
                    ReportService.SendAutoSave(response.id, report, this.patient)
                        .catch(error => console.warn(error));
                }
            } else if (!this.setting) { // when no setting has been found - could happen when its from dropdown - then try to trigger an export anyways
                ReportService.SendAutoSave(response.id, this.questionnaire?.name, this.patient)
                    .catch(error => console.warn(error));
            }

            this.isLoading = false;

            if (this.setting?.settings?.sendMailOnSave === true && this.setting?.settings?.mail) {
                console.warn('Checking mail-settings in ', this.setting.settings)
                const mailSettings : IMailSettings = this.setting.settings.mail;
                const errors: string[] = [];

                if (!mailSettings.to) errors.push('Invalid/No mail::to specified');
                if (!mailSettings.subject) errors.push('Invalid/No mail::subject specified');
                if (!mailSettings.body) errors.push('Invalid/No mail::body specified');
                if (!mailSettings.report) errors.push('Invalid/No mail::report specified');

                mailSettings.body = mailSettings.body
                    .replace(/%%PATIENTNAME%%/, this.patient.display)
                    .replace(/%%PATIENTNUMBER%%/, this.patient.patientNumber)
                    .replace(/%%FORMNAME%%/, this.questionnaire.title);

                mailSettings.subject = mailSettings.subject
                    .replace(/%%FORMNAME%%/, this.questionnaire.title)
                    .replace(/%%PATIENTNAME%%/, this.patient.display)
                    .replace(/%%PATIENTNUMBER%%/, this.patient.patientNumber);

                if (errors.length > 0) {
                    console.warn("Error when checking mail-settings in settings.mail:", this.setting, errors.join('\n'));
                    return;
                } else {
                    if (ConfigService.Debug) {
                        console.log(`
                            Mail will be sent:
                            to:${mailSettings.to}
                            with subject "${mailSettings.subject}"
                            with body:"${mailSettings.body}"
                            with report: "${mailSettings.report}"`);
                    }

                    ReportService.Mail(this.response.id, mailSettings.report, this.patient, undefined, mailSettings);
                }
            }

        }
    }

    async saveButtonStateChanged(sender: saveButton) {
        this.tooOld = sender.isTooOld;
        this.readonly = sender.buttonState !== "save";
        this.useQuestionnaireStatusForSave = true;
    }

    closeSaveButton() {
        //#region toggle the save button to be closed
        let currentlyOpenButton = document.querySelector(
            "div.open .save-questionnaire-button"
        );
        if (currentlyOpenButton) {
            let div = currentlyOpenButton.parentElement;
            if (div) {
                div.classList.remove("open");
            }
        }
        //#endregion
    }

    async saveButtonClick() {
        if (!this.response || this.tooOld) return;
        if (this.readonly && !this.tooOld) {
            // just enable writing
            this.readonly = false;
            return;
        }

        if (!this.readonly && !this.tooOld) {
            this.isLoading = true;
            let newStatus = this.response.status;
            try {
                switch (this.response.status) {
                    case fhirEnums.QuestionnaireResponseStatus.inProgress:
                        newStatus = fhirEnums.QuestionnaireResponseStatus.completed;
                        break;
                    case fhirEnums.QuestionnaireResponseStatus.completed:
                        newStatus = fhirEnums.QuestionnaireResponseStatus.amended;
                        break;
                }

                let bundle = [];
                // is editable and writeable, so ..
                // .. update on server ..
                if (this.questionnaire.id === this.qList.QAnamnesisId || this.questionnaire.id === this.qList.QAssessmentId) {
                    await this.analyzeService.analyse(
                        this.patient,
                        this.patient.latestAssessment
                    );

                    if (this.patient.latestAssessment.id !== this.response.id)
                        bundle.push(this.patient.latestAssessment);
                }

                bundle.push(this.response);
                if (!this.patient.flags) {
                    // await Fhir.Tools.CreateFlag(this.patient, this.fhirService);
                    await PatientItem.EnsureFlags(this.patient);
                }

                if (this.patient.flags) {
                    await this.analyzeService.validateMarks(this.patient);
                    bundle.push(this.patient.flags);
                }

                await this.fhirService.bundle(
                    bundle,
                    HTTPVerb.put,
                    BundleType.batch
                );

                // .. and make readonly.
                this.readonly = this.response.status === "completed" || this.response.status === "amended";

                this.afterResponseChanged(this.response);
                if (this.response.status === fhirEnums.QuestionnaireResponseStatus.amended ||
                    this.response.status === fhirEnums.QuestionnaireResponseStatus.completed) {
                    let report =
                        (this.setting && this.setting.report
                            ? this.setting.report.autoSaveReportName
                            : undefined) || this.report;
                    if (!this.report) this.report = this.questionnaire.name;

                    ReportService.SendAutoSave(
                        this.response.id,
                        report,
                        this.patient
                    );
                }
            } catch (e) {
                this.dialogMessages.prompt(
                    e.message || e.content || e.response || JSON.stringify(e),
                    this.i18n.tr("warning"),
                    true
                );
                debugger;
            } finally {
                this.hasChanges = false;
                this.responseBackup = NitTools.Clone(
                    this.response ? this.response.item : undefined
                );
                this.isLoading = false;
            }
        }
    }

    override async beforeSave(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            await super.beforeSave();
            try {
                if (this.response) {
                    // await this.analyzeService.analyse(this.patient, assessment, true, false);
                    // let isAssessment = this.questionnaire.name.toUpperCase().indexOf('ASSESSMENT') > -1; // .id === this.qList.QAssessmentId;
                    let isAssessment = this.route ? this.route.toUpperCase() === "ASSESSMENT" : false;
                    let isAnamnesis = this.route ? this.route.toUpperCase() === "ANAMNESIS" : false;
                    // let isAnamnesis = this.questionnaire.name.toUpperCase().replace("ANAMNESE", "ANAMNESIS").indexOf('ANAMNESIS') > -1; // .id === this.qList.QAnamnesisId;

                    if (isAssessment || isAnamnesis) {
                        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(this.patient.assessmentName);
                        let assessment = Fhir.Tools.StripId(this.response.questionnaire.reference) === questionnaireAssessment.id ? this.response : this.patient.latestAssessment;
                        if (assessment && assessment.status === 'in-progress')
                            assessment = this.patient.latestAssessment;

                        if (this.response.meta) this.response.meta.lastUpdated = new Date().toJSON();
                        this.recalcRisks(assessment, false)
                            .then(() => resolve(undefined))
                            .catch(error => {
                                console.warn(error);
                                resolve(undefined);
                            });
                    } else {
                        resolve(undefined);
                    }
                } else {
                    resolve(undefined);
                }
            } catch (error) {
                let msg = error.message || JSON.stringify(error);
                console.warn(msg);
                reject(msg);
            }
        });
    }

    public async setGlobalClinicalStatus(patient: PatientItem, newClinicalStatus: ('active'|'recurrence'|'inactive'|'remission'|'resolved'), doNotChangeEvidenceId: string, forItemsWithCurrentStatus: string) {
        let url = `Condition?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${patient.encounterId}`;
        if (forItemsWithCurrentStatus) {
            url += `&clinical-status=${forItemsWithCurrentStatus}`;
        }

        let all = <any[]>await this.fhirService.fetch(url);

        for (const condition of all) {
            let evidenceId: string = undefined;
            if (condition.evidence && condition.evidence[0] && condition.evidence[0].detail && condition.evidence[0].detail[0] && condition.evidence[0].detail[0].reference) {
                evidenceId = condition.evidence[0].detail[0].reference;
                if (evidenceId.indexOf('/') > -1) {
                    evidenceId = evidenceId.split('/')[1];
                }

                if (evidenceId !== doNotChangeEvidenceId) {
                    let response = patient.questionnaireResponses.find(o => o.id === evidenceId);
                    if (response && (response.status === 'amended' || response.status === 'completed')) {
                        let verificationStatus: ('provisional'|'differential'|'confirmed'|'refuted'|'entered-in-error'|'unknown') | undefined = 'confirmed';
                        if (verificationStatus !== condition.verificationStatus) {
                            condition.verificationStatus = verificationStatus;
                            condition["action"] = 'update';
                        }

                        if (condition.clinicalStatus !== newClinicalStatus) {
                            condition.clinicalStatus = newClinicalStatus;
                            condition["action"] = 'update';
                        }
                    }
                }
            }
        }

        let bundleItems: any[] = all.slice(); // .filter(o=>o['action'] === 'update');
        if (bundleItems.length > 0) {
            for (const item of bundleItems) {
                delete item["action"];
            }
            // if (ConfigService.Debug) console.debug('Setting status to ' + newClinicalStatus + ' for Conditions:', bundleItems);
            // await this.fhirService.bundle(bundleItems, HTTPVerb.put);
            //console.warn("CHECK SAVE!", bundleItems);
            for (const bi of bundleItems) {
                if (typeof this.bundleItems.find(o => o["id"] === bi["id"]) === "undefined") {
                    this.bundleItems.push(bi);
                } else {
                    console.warn("App wanted to add an Item without ID to bundle");
                }
            }
        }
    }

    /**
     * recursively removes the answers from the given item
     * @param responseItem the QuestionnaireResponse.Item to remove the answer component and it's children
     */
    removeAnswersFromItem(responseItem) {
        if (!responseItem) return;
        //if (ConfigService.Debug && responseItem.answer)
        //console.debug(`Removing answers in hidden item: ${responseItem.text} (${responseItem.linkId})`);

        delete responseItem.answer;
        if (responseItem.item) {
            for (const child of responseItem.item)
                this.removeAnswersFromItem(child);
        }
    }

    /**
     * removes the answers from hidden items and subitems
     */
    removeHiddenAnswers() {
        if (!this.questionnaire || !this.response) return;
        // get all linkIds in the Questionnaire including the groups. That way we don't need to
        // take a look at each items parent if it is a group and if it is visible, just work through
        // the list of linkIds and everything should be fine.
        const linkIds = Questionnaire.GetAllQuestionnaireItemLinkIds(this.questionnaire, true);
        for (const linkId of linkIds) {
            const questionnaireItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, linkId);
            if (!questionnaireItem // no item given ..
                || String(questionnaireItem.text).toUpperCase().startsWith('HIDDEN') ) // .. or special handling for group "Hidden Values in Assessment"
                continue; // skip to next linkId

            const itemVisible = Fhir.Tools.IsEnableWhenSatisfied(questionnaireItem, this.response);
            if (itemVisible) continue;

            const responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, questionnaireItem.linkId, false);
            this.removeAnswersFromItem(responseItem);
        }
    }

    /**
     * Is executed when the user clicks on the save-button on the form
     * @param status the status of the saved QuestionnaireResponse to be set
     */
    async headerSaveButtonClicked(status: string) {
        this.isLoading = true;

        this.bundleItems = [];
        this.bundleEntries = [];
        try {
            if (!this.patient)
                throw 'No current patient loaded';

            if (this.response) {
                if (['completed', 'amended'].indexOf(status) > -1) {
                    // validate whether all items marked as required has been answered
                    const validationResult = this.validateResponse(); // => { valid: boolean, missing: string[] }
                    const responseIsValid = validationResult.valid;
                    if (!responseIsValid) {
                        this.isLoading = false;
                        this.dialogMessages.prompt(this.i18n.tr('not_all_required_fields') + '<br />' + validationResult.missing.join('<br />'),
                            this.i18n.tr('warning'), true)
                            .whenClosed(() => this.readonly = false)
                            .catch(err => console.warn(err));

                        return;
                    }
                }

                this.removeHiddenAnswers(); // remove the hidden answers from the response

                RuntimeInfo.IsLoading = true;
                switch (status) {
                    default:
                    case "in-progress":
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.inProgress;
                        break;
                    case "amended":
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                        this.mayBeEdited = false;
                        this.readonly = true;
                        break;
                    case "completed":
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                        this.mayBeEdited = false;
                        this.readonly = true;
                        break;
                }

                try {
                    await this.beforeSave();
                } catch (e) {
                    if (e.message) e = e.message;
                    throw e;
                }

                // don't forget to update the existing response in the current patient
                this.patientService.addQuestionnaireResponse(this.patient,this.response,true);

                let formSettingAssessment = ConfigService.GetFormSettings('assessment');
                let formSettingAnamnesis = ConfigService.GetFormSettings('anamnesis');
                let questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(formSettingAssessment.questionnaireName);
                let questionnaireAnamnesis = QuestionnaireService.GetQuestionnaireByNameDirect(formSettingAnamnesis.questionnaireName);
                let isAssessment = this.questionnaire.id === questionnaireAssessment.id;
                let isAnamnesis = this.questionnaire.id === questionnaireAnamnesis.id;
                let lastAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaireAssessment.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                this.patient.latestAssessment = lastAssessment;

                /* BLOCK OK */
                if (lastAssessment && (isAssessment || isAnamnesis) && ['completed', 'amended'].indexOf(this.response.status) > -1) {
                    await this.analyzeService.analyse(
                        this.patient,
                        lastAssessment,
                        true,
                        false
                    );

                    await this.analyzeService.updateIcds(this.patient, lastAssessment);

                    if (this.response.id === this.patient.latestAssessment.id) {
                        // update all existing conditions for other finalized assessments with inactive status
                        await this.setGlobalClinicalStatus(
                            this.patient,
                            "inactive",
                            lastAssessment.id,
                            "active");
                    }
                } // */

                try {
                    await this.analyzeService.validateMarks(this.patient);
                } catch (err) {
                    console.warn(err.message || err);
                }

                try {
                    // let result = <any>await this.fhirService.update(this.response);
                    Fhir.Tools.UpdateAuthor(this.response, this.userService && this.userService.practitioner ? this.userService.practitioner : UserService.Practitioner);

                    if (this.patient.selectedAdditionalInfo)
                        this.bundleItems.push(this.response, this.patient.selectedAdditionalInfo);

                    if (!this.patient.flags)
                        await PatientItem.EnsureFlags(this.patient); // await Fhir.Tools.CreateFlag(this.patient, this.fhirService);

                    PatientService.AddQuestionnaireResponse(this.patient, this.response, true);

                    if (this.patient.flags) {
                        await this.analyzeService.validateMarks(this.patient);
                        this.createLatestDocumentFlag();

                        this.bundleItems.push(this.patient.flags);
                    }

                    if (isAssessment) {
                        if (!this.patient.latestAssessment) this.patient.latestAssessment = this.response;
                        const cfgBi = ConfigService.GetFormSettings('barthelindex');
                        const cfgBiEx = ConfigService.GetFormSettings('barthelindexEx');
                        const qBi = QuestionnaireService.GetQuestionnaireByNameDirect(cfgBi?.questionnaireName);
                        const qBiEx = QuestionnaireService.GetQuestionnaireByNameDirect(cfgBiEx?.questionnaireName);

                        let bi;
                        let biEx;

                        if (qBi?.name)
                            bi = await QuestionnaireResponse.GetAttachedResponse(this.patient, this.patient.latestAssessment, qBi.name);
                        if (qBiEx?.name)
                            biEx = await QuestionnaireResponse.GetAttachedResponse(this.patient, this.patient.latestAssessment, qBiEx.name);

                        if (bi)
                            this.bundleItems.push(bi);

                        if (biEx)
                            this.bundleItems.push(biEx);
                    }

                    if (typeof this.bundleItems.find(o => o.id === this.response.id) === "undefined") {
                        this.bundleItems.push(this.response);
                    }

                    const updateBundle = this.fhirService.getBundle(
                        this.bundleItems,
                        HTTPVerb.put,
                        BundleType.transaction
                    );

                    updateBundle.entry.push(...this.bundleEntries);

                    await this.fhirService.post(updateBundle, false);
                } catch (e) {
                    console.warn(`Exception: ${e}`);
                    this.dialogMessages.prompt(
                        e,
                        this.i18n.tr("warning"),
                        true
                    );
                }
                // this.__response = result;

                // update again, because we may have had changes inbetween
                for (const r of [this.patient.selectedAdditionalInfo, this.response, this.patient.latestAssessment]) {
                    PatientService.AddQuestionnaireResponse(this.patient,r,true);
                }

                await this.afterSave(this.response);
                // this.response.meta = result.meta;
            }

            this.hasChanges = false;
            this.responseBackup = NitTools.Clone(this.response?.item);
        }
        catch (e) {
            console.warn(e);
        }
        finally {
            RuntimeInfo.IsLoading = false;
        }

        return Promise.resolve();
    }

    async afterLoadedPatient(patient) {
        if (!patient || !this.route) return;

        this.updateSempaBirthField(patient);
    }


    async loadData() : Promise<void> {
        this.isLoading = true;

        await this.afterLoadedPatient(this.patient);

        await QuestionnaireService.Fetch(true);
        await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        const oldQuestionnaireName = this.questionnaireName;
        const oldQuestionnaire = this.questionnaire;
        try {
            const setting = ConfigService.GetFormSettings(this.route);
            if (setting)
                this.questionnaireName = setting.questionnaireName;
            await this.refreshFormName();
        } catch (e) {
            console.warn(e.message || e);
            this.questionnaireName = oldQuestionnaireName;
            this.questionnaire = oldQuestionnaire;
        }

        if (!this.questionnaire) {
            const result = await QuestionnaireService.GetQuestionnaireByName(this.questionnaireName);
            this.questionnaire = result;
        }

        BasicForm.pageTitle = this.questionnaire?.title;

        if (this.reAssignResponses) {
            if (!this.patient?.encounterId || !this.questionnaire?.id) {
                this.afterLoadedData();
                return;
            }

            let latestResponses: any[] = [];
            try {
                let qUrl = `QuestionnaireResponse?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=Encounter/${this.patient.encounterId}&questionnaire=${QuestionnaireService.GetQuestionnaireQueryUrl(this.questionnaire)}&status=amended,completed,in-progress&_sort=authored`;
                latestResponses = await this.fhirService.fetch(qUrl);
            } catch (error) {
                console.warn(error.message || error);
            }

            for (const r of latestResponses) {
                r.text = {
                    status: "generated",
                    // fhir_comments: [this.questionnaire.title],
                    div: `<div xmlns="http://www.w3.org/1999/xhtml">${moment(r.authored).format(RuntimeInfo.DateTimeFormat)}</div>`,
                };

                PatientService.AddQuestionnaireResponse(this.patient, r, true);
            }

            this.checkForAdditionalInfo();

            this.responses = QuestionnaireService.GetResponsesOfType(
                this.patient,
                this.questionnaire.id,
                [
                    fhirEnums.QuestionnaireResponseStatus.inProgress,
                    fhirEnums.QuestionnaireResponseStatus.amended,
                    fhirEnums.QuestionnaireResponseStatus.completed,
                ]
            );
        }

        this.afterLoadedData();
    }

    get loadingDocumentsText(): string {
        return this.i18n.tr("loadingDocuments");
    }

    afterLoadedData() {
        this.isLoading = false;
        this.documentLoaded = true;

        if (this.autoLoadId) {
            this.selectResponseId(this.autoLoadId).catch((err) =>
                console.warn(err)
            );
        }

        this.updateSempaBirthField(this.patient);
    }

    displayResponseInfo() {
        this.showResponseInfo(this.response);
    }

    displayStopDialog() {
        this.showStopDialog(this.response);
    }


    override afterResponseStopped(response: any) {
        super.afterResponseStopped(response);
        this.responses = QuestionnaireService.GetResponsesOfType(
            this.patient,
            this.questionnaire.id
        );
    }

    checkForAdditionalInfo() {
        if (this.patient && this.response) {
            if (this.patient.questionnaireResponses.length > 1) {
                this.patientService
                    .ensureQuestionnaireExistance(
                        this.patient,
                        this.qList.QAdditionalInfoId
                    )
                    .catch((error) => {
                        console.warn(error);
                    });
            }
        }
    }

    override async patientChanged(patient: PatientItem) {
        if (!patient) return;

        if (this.patient?.ward) await ConfigService.LoadConfigOverride(this.patient.ward, this.patient);
        await super.patientChanged(patient);
    }
}
